@import url('https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #0B0B0C;
  color: #fff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  margin: 0;
  border: 0;
  outline: none;
  font-family: 'Public Sans', sans-serif;
  line-height: 100%;
  box-sizing: border-box;
}


/* Scroll Bar */

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: none !important;
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: #ffffff26;
  border-radius: 6px;
  border: 3px solid transparent;
  background-clip: content-box;
  transition: all 150ms;
}

::-webkit-scrollbar-thumb:hover {
  background: #ffffff33;
  background-clip: content-box;
  transition: all 150ms;
}